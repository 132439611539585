import * as React from 'react';
import CvService from '../../services/CvService';

type CvProps = CvContextProps | null;

interface CvContextProps {
    cvService: CvService;
}

const useCvContext = () => React.useContext(CvContext);
const CvContext = React.createContext<CvProps>(null);

const CvContextProvider: React.FC<{ cvService: CvService }> = (props) => {
    return (
        <CvContext.Provider value={{
            cvService: props.cvService
        }}>
            {props.children}
        </CvContext.Provider>
    )
}

export { useCvContext, CvContextProvider};