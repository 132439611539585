import React from 'react';
import { Experience } from '../../models/Cv';
import Dates from '../../util/Dates';

interface ExperienceItemProps {
    experience: Experience
}

const ExperienceItem: React.FC<ExperienceItemProps> = ({experience}) => {
    return (
        <div>
            <strong>
                {experience.employer}, { Dates.getDateYears(experience, true) }
            </strong>
            <div>
                {experience.position}
            </div>
            <p className="experience-description">
                {experience.description}
            </p>
        </div>
    )
}

export default ExperienceItem;