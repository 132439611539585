import Http from "./Http";
import Message from "../models/Message";
import { getUuid } from "../util/UUIDs";

interface MessageService {
    getMessage(): Promise<Message>
}

export class DefaultMessageService implements MessageService {
    constructor(private http: Http) { }

    public async getMessage(): Promise<Message> {
        let message: Message;

        try {
            message = await this.http.get<Message>("/api/message");

            message.pageId = getUuid();
            message.error = false;
        } catch {
            message = {
                id: -1,
                text: "The page has stopped talking to you now",
                pageId: "",
                error: true
            };
        }

        return message;
    }
}

export default MessageService;