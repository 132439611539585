import Http from "./Http";
import { Cv } from "../models/Cv";

interface CvService {
    getCv(): Promise<Cv>
}

export class DefaultCvService implements CvService {
    constructor(private http: Http) { }

    public async getCv(): Promise<Cv> {
        return this.http.get<Cv>("/api/cv");
    }
}

export default CvService;