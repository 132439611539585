import React from 'react';
import { Cv } from '../../models/Cv';
import HeaderList from './HeaderList';
import ExperienceList from './ExperienceList';
import EducationList from './EducationList';
import { useCvContext } from '../../context/CV/CvContext';
import { useSetLoading, useIsLoading } from '../../context/Loading/LoadingContext';

import '../../styles/cv.css';

const CV: React.FC = () => {
    const [cv, setCv] = React.useState<Cv>({ headers: [], experiences: [], educations: [] });
    const cvContext = React.useRef(useCvContext());
    const setLoading = React.useRef(useSetLoading());
    const isLoading = useIsLoading();

    React.useEffect(() => {
        if (!cvContext.current) {
            return;
        }

        setLoading.current(true);

        cvContext.current.cvService.getCv().then((cvData) => {
            setCv(cvData);

        }).finally(() => {
            setLoading.current(false);
        });

        return () => {
            setCv({ headers: [], experiences: [], educations: [] });
        };
    }, []);

    if (isLoading) {
        return (<div className='loading-placeholder'></div>);
    }

    return (
        <div>
            <h1 className="text-center">Dean Parker</h1>
            <HeaderList headers={cv.headers}></HeaderList>
            <hr />
            <ExperienceList experience={cv.experiences}></ExperienceList>
            <hr />
            <EducationList education={cv.educations}></EducationList>
        </div>
    );
}

export default CV;