import React from 'react';
import NothingContext, { NothingContextProps } from './NothingContext';
import Message from '../../models/Message';
import MessageService from '../../services/MessageService';

type NothingContextProviderProps = {
    messageService: MessageService;
}

const NothingContextProvider: React.FC<NothingContextProviderProps> = (props) => {
    const [messages, setMessages] = React.useState<Message[]>([]);

    const updateMessages = async () => {
        const message = await props.messageService.getMessage();

        addMessage(message);

        setTimeout(() => {
            removeMessage(message);
        }, 5000);
    }

    const addMessage = (message: Message) => setMessages(current => current.concat(message));

    const removeMessage = (message: Message) => setMessages(current => current.filter(x => x.pageId !== message.pageId));

    const context: NothingContextProps = {
        messages: messages,
        onClick: async () => {
            await updateMessages();
        }
    }

    return (
        <NothingContext.Provider value={context}>
            {props.children}
        </NothingContext.Provider>
    );
}

export default NothingContextProvider;