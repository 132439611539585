import * as React from 'react';
import { Route, Switch } from 'react-router';
import { CvContextProvider } from '../context/CV/CvContext';
import NothingContextProvider from '../context/Nothing/NothingContextProvider';
import CvService from '../services/CvService';
import MessageService from '../services/MessageService';
import CV from './CV/CV';
import Nothing from './Nothing/Nothing';
import Home from './Home/Home';

interface AppProps {
    messageService: MessageService;
    cvService: CvService;
}

const AppRoutes: React.FC<AppProps> = ({messageService, cvService}) => (
    <Switch>
        <Route exact path='/cv' render={() => (
            <CvContextProvider cvService={cvService}>
                <CV />
            </CvContextProvider>
        )} />

        <Route exact path='/nothing' render={() => {
            return (
                <NothingContextProvider messageService={messageService}>
                    <Nothing />
                </NothingContextProvider>
            );
        }} />

        <Route path="/" component={Home} />
    </Switch>
)

export default AppRoutes