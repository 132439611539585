import React, { Component } from 'react';
import { Layout } from './components/Layout';

import './custom.css'
import { DefaultCvService } from './services/CvService';
import { DefaultMessageService } from './services/MessageService';
import { DefaultHttp } from './services/Http';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faTwitterSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons';

import AppRoutes from './components/AppRoutes';
import { Spinner } from 'reactstrap';
import { IsLoadingContext } from './context/Loading/LoadingContext';

export default class App extends Component {
    static displayName = App.name;

    render() {
        const http = new DefaultHttp();
        const messageService = new DefaultMessageService(http);
        const cvService = new DefaultCvService(http);

        return (
            <Layout>
                <IsLoadingContext.Consumer>
                    {loading => (
                        <>
                            <AppRoutes messageService={messageService}
                                cvService={cvService}
                            />
                            {loading &&
                                <div className="spinner-container text-center">
                                    <Spinner color="primary" />
                                </div>
                            }
                        </>
                    )}
                </IsLoadingContext.Consumer>

                <hr />
                <footer>
                    <div className="float-left">
                        &copy; Dean Parker 2020 - {new Date().getFullYear()}
                    </div>

                    <div className="float-right">
                        <a className="facebook-link" href="https://www.facebook.com/llamagrinder" title="Dean Parker on Facebook">
                            <FontAwesomeIcon icon={faFacebookSquare} size="2x" />
                        </a>
                        <a className="twitter-link" href="https://www.twitter.com/_deanparker" title="Dean Parker on Twitter">
                            <FontAwesomeIcon icon={faTwitterSquare} size="2x" />
                        </a>
                        <a className="linkedin-link" href="https://www.linkedin.com/in/dean-parker" title="Dean Parker on LinkedIn">
                            <FontAwesomeIcon icon={faLinkedin} size="2x" />
                        </a>
                    </div>
                </footer>
            </Layout>
        );
    }
}
