import React from 'react';

const Home: React.FC = () => {
    return (
        <>
            <h1>Hello.</h1>
            <div>This site is a perennial work in progress. But you can see my CV and the page which used to be here using the nav bar above.</div>
        </>);
}

export default Home;