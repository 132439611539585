import React from "react";
import { Fade, Button } from "reactstrap";

interface MessageButtonProps {
    onClick: React.MouseEventHandler<HTMLButtonElement>,
    text: string
}

const MessageButton: React.FC<MessageButtonProps> = ({onClick, text}) => {
    const [show, setShow] = React.useState(false);
    const showButton = () => setShow(true);

    setTimeout(showButton, 5000);

    return (
        <Fade in={show} tag="span">
            <Button
                className="btn-primary"
                onClick={onClick}>{text}</Button>
        </Fade>
    );
}

export default MessageButton