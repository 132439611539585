import React from 'react';
import { Header } from '../../models/Cv';

interface HeaderListProps {
    headers: Header[]
}

const HeaderList: React.FC<HeaderListProps> = ({headers}) => {

    const items = (
        <ul>
            {headers.map(header => (<li key={header.id}>{header.text}</li>))}
        </ul>
    );

    return !headers.length ? (<div>No headers to show</div>) : items;
}

export default HeaderList;