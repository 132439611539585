import * as React from 'react';
import Message from '../../models/Message';

interface MessageListProps {
    items: Message[]
}

const MessageList: React.FunctionComponent<MessageListProps> = ({items}) => {
    if (!items.length) {
        return (<div />);
    }

    const mappedItems = items.map(item => {
        return <li className={(item.error ? "text-danger bg-warning" : "")} key={item.pageId}>{item.text}</li>
    });

    return (
        <ul className="list-unstyled">{mappedItems}</ul>
    );
};

export default MessageList;