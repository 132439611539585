import React from 'react';
import MessageList from './MessageList';
import MessageButton from './MessageButton';
import { useNothingContext } from '../../context/Nothing/NothingContext';

import '../../styles/home.css';

const Nothing: React.FC = () => {
    const nothingContext = useNothingContext();

    return (
        <div className="text-center">
            <header>
                <h1>There's nothing to see here.</h1>
            </header>
            <div>
                <p>
                    <MessageButton onClick={() => nothingContext?.onClick()} text="Press me"></MessageButton>
                </p>
                <div className="messages">
                    <MessageList items={nothingContext?.messages || []} />
                </div>
            </div>
        </div>
    );
}

export default Nothing;
