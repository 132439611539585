import React from 'react';
import { Experience } from '../../models/Cv';
import { sortPeriodItems } from '../../util/Arrays';
import ExperienceItem from './ExperienceItem';

interface ExperienceListProps {
    experience: Experience[]
}

const ExperienceList: React.FC<ExperienceListProps> = ({experience}) => {
    experience = sortPeriodItems(experience, false);

    const items = (
        <ul className="list-unstyled">
            {experience.map(exp => (
                <li key={exp.id} className="mb-3">
                    <ExperienceItem experience={exp}></ExperienceItem>
                </li>))}
        </ul>
    );

    return (
        <>
            <h2>Experience</h2>
            <>
                {!experience.length ? (<div>No experience to show</div>) : items}
            </>
        </>
    );
}

export default ExperienceList;