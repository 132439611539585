import React from 'react';
import { Education } from '../../models/Cv';
import Dates from '../../util/Dates';

interface EducationItemProps {
    education: Education
}

const EducationItem: React.FC<EducationItemProps> = ({ education }) => {
    return (
        <div>
            <strong>{education.institution}, { Dates.getDateYears(education) }</strong>
            <div>
                {education.course}, {education.result}
            </div>
        </div>
    )
}

export default EducationItem;