import { PeriodItem } from "../models/Cv";

export function swap<T>(arr: Array<T>, index1: number, index2: number) {
    let _arr = [...arr];
    let temp = _arr[index1];
    _arr[index1] = _arr[index2];
    _arr[index2] = temp;
    
    return _arr
}

export function sortPeriodItems<T extends PeriodItem>(arr: Array<T>, asc: boolean = true) : Array<T> {
    const direction = asc ? 1 : -1;
    
    return arr.sort((a, b) => a.startDate < b.startDate ? -direction : direction);
}