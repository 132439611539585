import { PeriodItem } from '../models/Cv';

const monthFormatterOptions: Intl.DateTimeFormatOptions = {
    month: "short",
    year: "numeric"
}

const yearFormatterOptions: Intl.DateTimeFormatOptions = {
    year: "numeric"
}

const createFormatter = (options: Intl.DateTimeFormatOptions) => Intl.DateTimeFormat([navigator.language], options);

const Dates = {
    getDateYears: (dates: PeriodItem, includeMonths: boolean = false) => {
        const formatterOptions: Intl.DateTimeFormatOptions = includeMonths ? monthFormatterOptions : yearFormatterOptions;
        const formatter = createFormatter(formatterOptions);

        const end = dates.endDate ? formatter.format(new Date(dates.endDate)) : "Present";

        return `${formatter.format(new Date(dates.startDate))} - ${end}`;
    } 
}

export default Dates;