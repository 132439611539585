import * as React from 'react';
import Message from '../../models/Message';

export interface NothingContextProps {
    messages: Message[];
    onClick(): Promise<void>; 
}

const NothingContext = React.createContext<NothingContextProps | null>(null);
export const useNothingContext = () => React.useContext(NothingContext);

export default NothingContext;