import React from 'react';
import { Education } from '../../models/Cv';
import { sortPeriodItems } from '../../util/Arrays';
import EducationItem from './EducationItem';

interface EducationListProps {
    education: Education[]
}

const EducationList: React.FC<EducationListProps> = ({ education }) => {
    education = sortPeriodItems(education, false);

    const items = (
        <ul className="list-unstyled">
            {education.map(exp => (<li key={exp.id}><EducationItem education={exp}></EducationItem></li>))}
        </ul>
    );

    return (
        <>
            <h2>Education</h2>
            <>
                { !education.length ? (<div>No education to show</div>) : items}
            </>
        </>
    );
}

export default EducationList;