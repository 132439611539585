import * as React from 'react';

const IsLoadingContext = React.createContext<boolean>(false);
const SetLoadingContext = React.createContext<(l: boolean) => void>(() => { return; });

const useIsLoading = () => React.useContext(IsLoadingContext);
const useSetLoading = () => React.useContext(SetLoadingContext);

const LoadingContextProvider: React.FC = (props) => {
    const [loading, setLoading] = React.useState(false);

    return (
        <IsLoadingContext.Provider value={loading}>
            <SetLoadingContext.Provider value={setLoading}>
                {props.children}
            </SetLoadingContext.Provider>
        </IsLoadingContext.Provider>
    )
}

export { useIsLoading, useSetLoading, IsLoadingContext, LoadingContextProvider };