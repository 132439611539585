interface Http {
    get<T>(url: string): Promise<T>;
    post<T>(url: string, item: T): Promise<T | undefined>;
    delete(url: string): Promise<void>;
}

export class DefaultHttp implements Http {
    async delete(url: string): Promise<void> {
        const response = await fetch(url, {
            method: "DELETE",
        });

        if (!response.ok) {
            throw new Error(`${response.status}: ${response.statusText}`);
        }
    }

    public async get<T>(url: string): Promise<T> {
        const response = await fetch(url);

        if (!response.ok) {
            throw new Error(response.statusText);
        }

        return await response.json();
    }

    public async post<T>(url: string, item: T): Promise<T | undefined> {
        
        const response = await fetch(url, {
            method: "POST",
            body: JSON.stringify(item),
            headers: {
                'Content-Type': 'application/json'
            }            
        });

        if (!response.ok) {
            throw new Error(`${response.status}: ${response.statusText}`);
        }

        try {
            return await response.json();

        } catch {
            // No body so return.
        }

        return undefined;
    }
}

export default Http;