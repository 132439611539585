import React from 'react';
import { Container } from 'reactstrap';
import SiteNav from './SiteNav';

export const Layout: React.FC = ({ children }) => {
  return (
    <Container>
      <SiteNav></SiteNav>
      <div>
        {children}
      </div>
    </Container>
  );
}